import React from 'react';
import Slideshow from './Slideshow';
import './Hero.css';

const Hero = () => (
  <section id="home" className="hero">
    <h1>Welcome to ZoneZapp</h1>
    <p>Connecting Communities in Real-Time</p>
    <Slideshow />
    <a href="#features" className="cta-button">Learn More</a>
  </section>
);

export default Hero;
