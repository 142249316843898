import React from 'react';
import './Contact.css';

const Contact = () => (
  <section id="contact" className="contact">
    <h2>Contact Us</h2>
    <a href="https://www.linkedin.com/company/zonezapp-tech-ltd/" target="_blank" rel="noopener noreferrer">
      <i className="fab fa-linkedin"></i> Connect on LinkedIn
    </a>
  </section>
);

export default Contact;
