import React from 'react';
import './About.css';

const About = () => (
  <section id="about" className="about">
    <h2>About ZoneZapp</h2>
    <p>ZoneZapp is a location-based image sharing platform enabling users to request and share real-time information within a 5km radius. Our mission is to foster community engagement and provide users with authentic, localized content.</p>
  </section>
);

export default About;
