import React from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App = () => (
  <div className="App">
    <Header />
    <Hero />
    <Features />
    <About />
    <Contact />
    <Footer />
  </div>
);

export default App;
