import React from 'react';
import './Features.css';

const Features = () => (
  <section id="features" className="features">
    <h2>Features</h2>
    <div className="feature-item">
      <h3>Real-Time Updates</h3>
      <p>Get instant notifications about events and news in your area.</p>
    </div>
    <div className="feature-item">
      <h3>Community Engagement</h3>
      <p>Engage with your local community through discussions and sharing.</p>
    </div>
    <div className="feature-item">
      <h3>Enterprise Accounts</h3>
      <p>Businesses can create official accounts and advertise their products.</p>
    </div>
  </section>
);

export default Features;
