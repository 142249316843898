import React from 'react';
import Slider from 'react-slick';
import './Slideshow.css';
import slide1 from '../slides/slide1.mp4';
import slide2 from '../slides/slide2.jpg';
import slide3 from '../slides/slide3.gif';

const Slideshow = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 100,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: false
  };

  return (
    <div className="slideshow">
      <Slider {...settings}>
        <div>
          <video controls autoPlay muted>
            <source src={slide1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <img src={slide2} alt="Slide 2" />
        </div>
        <div>
          <img src={slide3} alt="Slide 3" />
        </div>
      </Slider>
    </div>
  );
};

export default Slideshow;
