import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer>
    <p>&copy; 2024 ZoneZapp. All rights reserved.</p>
    <div className="social-links">
      <a href="https://www.linkedin.com/company/zonezapp-tech-ltd/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
    </div>
  </footer>
);

export default Footer;
